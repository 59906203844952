import { addActive, getList } from "@/api/api";
export default {
  name: "index",
  props: ['Row'],
  data() {
    return {
      token: localStorage.getItem('token'),
      Info: {
        id: '',
        name: '',
        type: 1,
        time_range: []
      },
      schoolList: [],
      gradeList: []
    };
  },
  mounted() {},
  methods: {
    closeDiv() {
      this.$emit('closeDiv');
    },
    timeRangeChange(e) {
      this.Info.start_time = e[0];
      this.Info.end_time = e[1];
    },
    getInfo(row) {
      //清空表单
      this.getList();
      if (row) {
        let info = JSON.parse(row);
        info.time_range = [info.start_time, info.end_time];
        this.Info = info;
      } else {
        this.Info = {};
      }
    },
    getList() {
      let data = {};
      getList(data, 'School').then(res => {
        this.schoolList = res.data;
      });
      getList(data, 'Grade').then(res => {
        this.gradeList = res.data;
      });
    },
    subData() {
      //提交
      if (!this.Info.grade_level || !this.Info.name) {
        this.$root.ElMessage.error('考试活动名称和年级不能为空！');
        return false;
      }
      if (!this.Info.start_time || !this.Info.end_time) {
        this.$root.ElMessage.error('请选择开始和结束时间！');
        return false;
      }
      addActive(this.Info).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.closeDiv();
          this.$emit('getList');
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    },
    handleAvatarSuccess(e) {
      //图片上传成功后
      this.Info.businessLicenseImg = e.data;
    }
  }
};